import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from "@/style/images/hny_icon_small.png";
import logoText from '@/style/images/hny_icon.png';

import {
  MenuOutlined,
  SettingOutlined,
  DashboardOutlined,
  BankOutlined,
  ReadOutlined,
  ProjectFilled,
  ProjectOutlined,
  MonitorOutlined,
  CarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  PayCircleOutlined,
  DiffOutlined ,
  CheckSquareOutlined,
  DollarOutlined
} from "@ant-design/icons";
import { AdminPanelSettingsOutlined, Inventory2Outlined, TaskAltOutlined } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
import { crud } from '@/redux/crud/actions';
import { selectMenu } from '@/redux/crud/selectors';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  return (
    <>
      <div className="sidebar-wraper">
        <Sidebar collapsible={true} />
      </div>
      <MobileSidebar />
    </>
  );
}

function Sidebar({ collapsible }) {
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  const dispatch = useDispatch();
  const { result: objMenu, isLoading: listIsLoading, } = useSelector(selectMenu);
  useEffect(() => {
    dispatch(crud.menu());
  }, []);
  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  if (!objMenu) {
    return null;
  }

  return (
    <>
      <Sider
        collapsible={collapsible}
        collapsed={collapsible ? isNavMenuClose : collapsible}
        onCollapse={onCollapse}
        className="navigation"
      >
        <div className="logo">
          {showLogoApp && (
            <img
              src={logoIcon}
              alt="Logo"
              // style={{ margin: "0 auto 40px", display: "block" }}
            />
          )}

          {!showLogoApp && (
            <img
              src={logoText}
              alt="Logo"
              style={{ marginTop: "3px", marginLeft: "10px" }}
            />
          )}
        </div>
        {!listIsLoading && (
          <Menu mode="inline">
            <Menu.Item key={"Dashboard"} icon={<DashboardOutlined />}>
              <Link to={"/"} />
              Dashboard
            </Menu.Item>
            {objMenu?.Reservation?.isView ? (
              <Menu.Item key={"Reservation"} icon={<ProjectOutlined />}>
                <Link to={"/reservation"} />
                Reservation
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.Customer?.isView ? (
              <Menu.Item key={"Customer"} icon={<UserOutlined />}>
                <Link to={"/customer"} />
                Customer
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.ClientPayment?.isView ? (
              <Menu.Item key={"Payment"} icon={<DollarOutlined />}>
                <Link to={"/payment"} />
                Payments
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.Car?.isView ? (
              <Menu.Item key={"Car"} icon={<CarOutlined />}>
                <Link to={"/car"} />
                Car
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.Employees?.isView ? (
              <Menu.Item key={"Employees"} icon={<UsergroupAddOutlined />}>
                <Link to={"/employee"} />
                Employees
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.TaskManage?.isView ? (
              <Menu.Item key={"TaskManage"} icon={<SolutionOutlined />}>
                <Link to={"/task_management"} />
                Task Management
              </Menu.Item>
            ) : (
              ""
            )}

            {objMenu?.Expenses?.isView ? (
              <Menu.Item key={"Expenses"} icon={<PayCircleOutlined />}>
                <Link to={"/expense_entry"} />
                Expenses
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.Car?.isView ? (
              <Menu.Item key={"AddOnItem"} icon={<DiffOutlined />}>
                <Link to={"/addonitem"} />
                Add On Gadgets
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.Transaction?.isView ? (
              <Menu.Item key={"Transaction"} icon={<ReadOutlined />}>
                <Link to={"/transaction"} />
                Transations
              </Menu.Item>
            ) : (
              ""
            )}
            {objMenu?.UserPermission?.isView ? (
              <Menu.Item key={"UserPermission"} icon={<CheckSquareOutlined />}>
                <Link to={"/user_permission"} />
                User Permission
              </Menu.Item>
            ) : (
              ""
            )}
            <SubMenu key={"Reports"} icon={<ReadOutlined />} title={"Reports"}>
              {objMenu?.Daybook?.isView ? (
                <Menu.Item key={"DayBook"}>
                  <Link to={"/day_book"} />
                  Day Book
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu["Closing report"]?.isView ? (
                <Menu.Item key={"ClosingReport"}>
                  <Link to={"/closing_rpt"} />
                  Closing Report
                </Menu.Item>
              ) : (
                ""
              )}

              <Menu.Item key={"Profit&loss"}>
                <Link to={"/profit_loss"} />
                Profit & loss
              </Menu.Item>
              {objMenu["Balance Sheet"]?.isView ? (
                <Menu.Item key={"BalanceSheet"}>
                  <Link to={"/balance_sheet"} />
                  Balance Sheet
                </Menu.Item>
              ) : (
                ""
              )}
              <Menu.Item key={"CashFlow"}>
                <Link to={"/cash_flow"} />
                Cash Flow
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key={"Master"}
              icon={<AdminPanelSettingsOutlined />}
              title={"Masters"}
            >
              {objMenu?.Customer?.isView ? (
                <Menu.Item key={"Customer"}>
                  <Link to={"/client"} />
                  Customer
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu?.Supplier?.isView ? (
                <Menu.Item key={"Supplier"}>
                  <Link to={"/suppliers"} />
                  Supplier
                </Menu.Item>
              ) : (
                ""
              )}

              <Menu.Item key={"Models"}>
                <Link to={"/models"} />
                Models
              </Menu.Item>

              {objMenu?.Contractor?.isView ? (
                <Menu.Item key={"Contractors"}>
                  <Link to={"/contractor"} />
                  Contractors
                </Menu.Item>
              ) : (
                ""
              )}
              {objMenu["Cash Accounts"]?.isView ? (
                <Menu.Item key={"CashAccount"}>
                  <Link to={"/account/cash"} />
                  Cash Account
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu["Bank Accounts"]?.isView ? (
                <Menu.Item key={"BankAccount"}>
                  <Link to={"/account/bank"} />
                  Bank Account
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu["Other Accounts"]?.isView ? (
                <Menu.Item key={"OtherAccount"}>
                  <Link to={"/account/other"} />
                  Other Account
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu?.Materials?.isView ? (
                <Menu.Item key={"Materials"}>
                  <Link to={"/materials"} />
                  Materials
                </Menu.Item>
              ) : (
                ""
              )}
              {objMenu?.Units?.isView ? (
                <Menu.Item key={"Unit"}>
                  <Link to={"/unit"} />
                  Units
                </Menu.Item>
              ) : (
                ""
              )}
              {objMenu?.Branch?.isView ? (
                <Menu.Item key={"Branch"}>
                  <Link to={"/branch"} />
                  Branch
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu?.Company ? (
                <Menu.Item key={"Company"}>
                  <Link to={"/company"} />
                  Company
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu["All Type"]?.isView ? (
                <Menu.Item key={"AllTypes"}>
                  <Link to={"/all_types"} />
                  All Types
                </Menu.Item>
              ) : (
                ""
              )}
            </SubMenu>
            <SubMenu
              key={"Settings"}
              icon={<SettingOutlined />}
              title={"Settings"}
            >
              {objMenu["User Permission"]?.isView ? (
                <Menu.Item key={"UserManagement"}>
                  <Link to={"/users"} />
                  Users
                </Menu.Item>
              ) : (
                ""
              )}

              {objMenu["User Permission"]?.isView ? (
                <Menu.Item key={"UserActivity"}>
                  <Link to={"/useractivity"} />
                  User Activity
                </Menu.Item>
              ) : (
                ""
              )}
              <Menu.Item key={"PrintSettings"}>
                <Link to={"/printSettings"} />
                Print Settings
              </Menu.Item>
              {objMenu["User Permission"]?.isView ? (
                <Menu.Item key={"UserPermission"}>
                  <Link to={"/user_permission"} />
                  User Permission
                </Menu.Item>
              ) : (
                ""
              )}
              <Menu.Item key={"GeneralSettings"}>
                <Link to={"/generalsettings"} />
                General Settings
              </Menu.Item>
            </SubMenu>
          </Menu>
        )}
      </Sider>
    </>
  );
}

function MobileSidebar() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="text" size="large" onClick={showDrawer} className="mobile-sidebar-btn">
        <MenuOutlined />
      </Button>
      <Drawer
        width={200}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="mobile-sidebar-wraper"
      >
        <Sidebar collapsible={false} />
      </Drawer>
    </>
  );
}
